import { NextPage } from 'next';

import { useIntl } from 'apps/freshbuffer/src/core/Intl';
import { PageLayout } from 'apps/freshbuffer/src/core/PageLayout';

import { Typography } from '@mui/material';
import styles from './ErrorNotFound.module.css';

const ErrorNotFound: NextPage = () => {
  const { T } = useIntl();

  const ERROR_CODE_HEADING = '404';

  return (
    <PageLayout pageTitle={T('LBL_PAGETITLE_APPLICATION_ERROR')}>
      <PageLayout.MainContainer>
        <PageLayout.SectionContainer className={styles.root}>
          <Typography
            fontSize={48}
            variant="body2"
            fontWeight={700}
            marginBottom={3}
          >
            {ERROR_CODE_HEADING}
          </Typography>
          <Typography
            fontSize={28}
            variant="body2"
            fontWeight={600}
            lineHeight={1.2}
            marginBottom={2}
          >
            {T('400_error_page.heading.doesnt_exist')}
          </Typography>
        </PageLayout.SectionContainer>
      </PageLayout.MainContainer>
    </PageLayout>
  );
};

export default ErrorNotFound;
